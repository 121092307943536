<template>
  <footer id="footer" class="footer">
    <section class="call-to-action">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div
              style="
                font-size: 15px;
                text-align: center;
                color: #fff;
                font-family: sans-serif;
              "
            >
              &copy; {{ currentYear }} {{ companyName }}. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "footer-section",
  data() {
    return {
      appName: this.$func.appname,
      appVersion: this.$func.appversion,
      companyName: this.$func.companyname,
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}
</style>
