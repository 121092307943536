var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header"},[_c('nav',{staticClass:"navbar nav-shadow",staticStyle:{"padding":"10px 0 0 15px"}},[_vm._m(0),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"navbar-holder d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"navbar-header"},[_c('router-link',{staticClass:"navbar-brand d-none d-sm-inline-block",attrs:{"to":"/","exact":""}},[_c('div',{staticClass:"brand-text d-none d-lg-inline-block"},[_c('img',{attrs:{"src":require("@/assets/img/connect_logo.png"),"height":"71px"}}),_vm._v("   "),_c('img',{staticStyle:{"padding-top":"10px"},attrs:{"src":require("@/assets/img/Suntory_Global_Spirits_Logo.png"),"height":"71px"}})])])],1),(
            _vm.userName !== null && _vm.userName !== undefined && _vm.userName !== ''
          )?_c('ul',{staticClass:"nav-menu list-unstyled d-flex flex-md-row align-items-md-center"},[_c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link language dropdown-toggle",attrs:{"id":"languages","rel":"nofollow","data-target":"#","href":"#","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{staticStyle:{"color":"#9d1c31"}},[_vm._v("Hello, ")]),_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.userName))]),(
                  _vm.profilePic !== undefined &&
                  _vm.profilePic !== null &&
                  _vm.profilePic !== ''
                )?_c('img',{attrs:{"src":_vm.profilePic,"alt":_vm.userName}}):_c('div',{style:('background: ' +
                  this.$func.getRandomBackColor() +
                  ' !important;'),attrs:{"id":"profileImage"}},[_vm._v(" "+_vm._s(_vm.$func.profileNameShorter(_vm.userName))+" ")])]),_c('ul',{staticClass:"dropdown-menu",staticStyle:{"display":"none"},attrs:{"aria-labelledby":"languages"}},[_c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"rel":"nofollow","href":"javascript:void(0);"},on:{"click":function($event){$event.preventDefault();return _vm.signOut.apply(null, arguments)}}},[_vm._v(" Logout")])])])])]):_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"disabled":_vm.loginIsProcessing},on:{"click":function($event){$event.preventDefault();return _vm.signIn.apply(null, arguments)}}},[(_vm.loginIsProcessing)?_c('i',{staticClass:"fa fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.loginIsProcessing ? "Processing" : "Login")+" ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-box"},[_c('button',{staticClass:"dismiss"},[_c('i',{staticClass:"icon-close"})]),_c('div',{attrs:{"id":"searchForm","role":"search"}},[_c('input',{staticClass:"form-control",attrs:{"type":"search","placeholder":"What are you looking for..."}})])])}]

export { render, staticRenderFns }