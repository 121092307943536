<template>
  <header class="header">
    <nav class="navbar nav-shadow" style="padding: 10px 0 0 15px">
      <div class="search-box">
        <button class="dismiss"><i class="icon-close"></i></button>
        <div id="searchForm" role="search">
          <input
            type="search"
            placeholder="What are you looking for..."
            class="form-control"
          />
        </div>
      </div>
      <div class="container-fluid">
        <div
          class="navbar-holder d-flex align-items-center justify-content-between"
        >
          <div class="navbar-header">
            <router-link
              to="/"
              class="navbar-brand d-none d-sm-inline-block"
              exact
            >
              <div class="brand-text d-none d-lg-inline-block">              
                <img src="@/assets/img/connect_logo.png" height="71px" />&nbsp;&nbsp;
                <img src="@/assets/img/Suntory_Global_Spirits_Logo.png" height="71px" style="padding-top: 10px;"/>
              </div>
            </router-link>
          </div>
          <ul
            v-if="
              userName !== null && userName !== undefined && userName !== ''
            "
            class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center"
          >
            <li class="nav-item dropdown">
              <a
                id="languages"
                rel="nofollow"
                data-target="#"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="nav-link language dropdown-toggle"
              >
                <span style="color: #9d1c31">Hello, </span>
                <span style="margin-right: 10px">{{ userName }}</span>
                <img
                  v-if="
                    profilePic !== undefined &&
                    profilePic !== null &&
                    profilePic !== ''
                  "
                  :src="profilePic"
                  :alt="userName"
                />
                <div
                  v-else
                  id="profileImage"
                  :style="
                    'background: ' +
                    this.$func.getRandomBackColor() +
                    ' !important;'
                  "
                >
                  {{ $func.profileNameShorter(userName) }}
                </div></a
              >
              <ul
                aria-labelledby="languages"
                class="dropdown-menu"
                style="display: none"
              >
                <li>
                  <a
                    rel="nofollow"
                    href="javascript:void(0);"
                    @click.prevent="signOut"
                    class="dropdown-item"
                  >
                    Logout</a
                  >
                </li>
              </ul>
            </li>
          </ul>
          <button
            class="btn btn-outline-secondary"
            v-else
            @click.prevent="signIn"
            :disabled="loginIsProcessing"
          >
            <i v-if="loginIsProcessing" class="fa fa-spinner fa-spin"></i
            >&nbsp;{{ loginIsProcessing ? "Processing" : "Login" }}
          </button>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "header-section",
  data() {
    return {
      loginIsProcessing: false,
      appName: this.$func.appname,
      appVersion: this.$func.appversion,
      userName: "",
      profilePic: "",
    };
  },
  created() {
    const _vm = this;
    const myMSALObj = _vm.$func.msalObject;
    myMSALObj
      .handleRedirectPromise()
      .then(_vm.handleResponse)
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    resetStore: function () {
      const _vm = this;
      try {
        _vm.loginIsProcessing = JSON.parse(
          localStorage.getItem("loginprocessing")
        );
        _vm.$store.state.LoginResponse = JSON.parse(
          localStorage.getItem("LoginResponse")
        );
        _vm.profilePic = _vm.$store.state.userprofilepic =
          localStorage.getItem("userprofilepic");
        _vm.userName = _vm.$store.state.LoginResponse.account.name;
        //console.log(_vm.$store.state.LoginResponse.accessToken);
        // eslint-disable-next-line no-empty
      } catch {}
    },
    handleResponse: function (response) {
      const _vm = this;
      _vm.resetStore();
      if (response !== null) {
        _vm.$store.state.LoginResponse = response;
        _vm.userName = _vm.$store.state.LoginResponse.account.name;
        localStorage.setItem(
          "LoginResponse",
          JSON.stringify(_vm.$store.state.LoginResponse)
        );
        _vm.getProfilePicture(_vm.$store.state.LoginResponse.accessToken);
        _vm.userName = _vm.$store.state.LoginResponse.account.name;
        _vm.loginIsProcessing = false;
        localStorage.setItem("loginprocessing", false);
        if (_vm.$router.currentRoute.path !== "/") {
          _vm.$router.push({ path: "/" });
        }
      } else {
        _vm.tryGetAccountDetails();
      }
    },
    tryGetToken: function () {
      const _vm = this;
      _vm.getTokenSilent(_vm.$func.loginRequest).then((response) => {
        _vm.$store.state.LoginResponse = response;
        localStorage.setItem("LoginResponse", JSON.stringify(response));
        //_vm.$router.push({ path: "/" });
        _vm.loginIsProcessing = false;
        //localStorage.setItem("LoginResponse", false);
      });
    },
    getTokenSilent: function (request) {
      const _vm = this;
      const myMSALObj = _vm.$func.msalObject;
      request.account = myMSALObj.getAccountByUsername(
        _vm.$store.state.LoginResponse.account.username
      );
      return myMSALObj.acquireTokenSilent(request).catch((error) => {
        console.warn(
          "silent token acquisition fails. acquiring token using redirect"
        );
        // eslint-disable-next-line no-undef
        if (error instanceof msal.InteractionRequiredAuthError) {
          return myMSALObj.acquireTokenRedirect(request);
        } else {
          console.warn(error);
        }
      });
    },
    getProfilePicture: async function (token) {
      const _vm = this;
      try {
        _vm
          .$axios(_vm.$func.profilePicGraphEndPointSmall, {
            headers: { Authorization: `Bearer ${token}` },
            responseType: "arraybuffer",
          })
          .then((response) => {
            console.log(response);
            _vm.profilePic = _vm.$store.state.userprofilepic =
              "data:image/jpeg;base64, " +
              new Buffer(response.data, "binary").toString("base64");
            localStorage.setItem("userprofilepic", _vm.profilePic);
          })
          .finally(function () {
            //_vm.tryGetToken();
          });
      } catch (ex) {
        console.warn(ex);
      }
    },
    signIn: function () {
      const _vm = this;
      const myMSALObj = _vm.$func.msalObject;
      _vm.loginIsProcessing = true;
      localStorage.setItem("loginprocessing", true);
      myMSALObj.loginRedirect(_vm.$func.loginRequest);
    },
    signOut: function () {
      const _vm = this;
      _vm.$func
        .sConfirm(
          "Are you sure you want to logout?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            const myMSALObj = _vm.$func.msalObject;
            myMSALObj.logout();
            _vm.$store.state.userprofilepic = null;
            _vm.$store.state.LoginResponse = null;
            sessionStorage.clear();
            localStorage.clear();
            if (_vm.$router.currentRoute.path !== "/login") {
              _vm.$router.push({ path: "/login" });
            }
          }
        });
    },
    selectAccount: function () {
      const _vm = this;
      const myMSALObj = _vm.$func.msalObject;
      return myMSALObj.getAllAccounts();
    },
    tryGetAccountDetails: function () {
      const _vm = this;
      if (_vm.selectAccount() !== null) {
        _vm.resetStore();
      } else {
        _vm.$store.state.userprofilepic = null;
        _vm.$store.state.LoginResponse = null;
        sessionStorage.clear();
        localStorage.clear();
        if (_vm.$router.currentRoute.path !== "/") {
          _vm.$router.push({ path: "/" });
        }
      }
    },
  },
};
</script>

<style scoped>
.nav-shadow {
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}
#profileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #512da8;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  line-height: 40px;
  display: inline-block;
}
</style>
