import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueProgressBar from "vue-progressbar";
import BlockUI from "vue-blockui";
import axios from "axios";
import { func } from "./globalFunctions";

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(VueProgressBar, {
  color: "rgb(143, 255, 199)",
  failedColor: "red",
  height: "2px",
});
Vue.use(BlockUI);
Vue.prototype.$func = func;
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
