<template>
  <div id="app">
    <BlockUI :message="msg" v-if="showloader">
      <img src="@/assets/img/loader.svg" style="text-align: center" alt="" />
    </BlockUI>
    <header-section></header-section>
    <!--<div style="margin-bottom: 30px"></div>-->
    <router-view />
    <footer-section></footer-section>
  </div>
</template>

<script>
import HeaderSection from "@/components/common/header-section";
import FooterSection from "@/components/common/footer-section";
export default {
  components: { FooterSection, HeaderSection },
  data() {
    return {
      appName: this.$func.appname,
      appVersion: this.$func.appversion,
      companyName: this.$func.companyname,
      msg: "Loading... Please wait...",
      showloader: false,
    };
  },
  beforeCreate() {
    const _vm = this;
    if (JSON.parse(localStorage.getItem("LoginResponse"))) {
      _vm.$router.push({ path: "/" });

      _vm
        .$axios({
          url: process.env.VUE_APP_SERVER_API + "api/SpointEmployerProvider",
          method: "GET",
        })
        .then((response) => {
          _vm.$store.state.employers = response.data.message.employers;
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      _vm.$router.push({ path: "/login" });
    }
  },
  created() {
    const _vm = this;
    _vm.$axios.interceptors.request.use(
      (config) => {
        _vm.showloader = true;
        return config;
      },
      (error) => {
        _vm.showloader = false;
        return Promise.reject(error);
      }
    );
    _vm.$axios.interceptors.response.use(
      (response) => {
        _vm.showloader = false;
        return response;
      },
      (error) => {
        _vm.showloader = false;
        /*if (error.response.status === 401) {
          _vm.$func
            .sAlert(
              "Session Expired",
              "warning",
              "Your current session has been expired. Please login again to continue.",
              _vm.$swal
            )
            .then(function() {
              try {
                const myMSALObj = _vm.$func.msalObject;
                myMSALObj.logout();
                _vm.$store.state.userprofilepic = null;
                _vm.$store.state.LoginResponse = null;
                sessionStorage.clear();
                localStorage.clear();
                if (_vm.$router.currentRoute.path !== "/") {
                  _vm.$router.push({ path: "/" });
                }
                // eslint-disable-next-line no-empty
              } catch {}
            });
        }*/
        return Promise.reject(error);
      }
    );
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      document.title = to.meta.title + " - " + this.appName;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.btn-round {
  height: 50px;
  width: 50px;
  font-weight: 900;
  font-size: 24px;
  border-radius: 50%;
}
.fa-color-primary {
  color: #007bff;
}
.fa-color-secondary {
  color: #6c757d;
}
.fa-color-success {
  color: #28a745;
}
.fa-color-warning {
  color: #ffc107;
}
.fa-color-info {
  color: #17a2b8;
}
.fa-color-danger {
  color: #dc3545;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  /*background-color: #4fdc5a;*/
  background-color: #51c6db;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
